<template>
    <div>
        <cool-select
            v-model="selected"
            :items="items"
            :loading="loading"
            :item-text="value"
            :input-text="inputText"
            :placeholder="placeholder"
            disable-filtering-by-search
            @search="onSearch"
        >
            <template #no-data>
                {{
                    noData
                        ? "Nebyl nalezen žádný výsledek."
                        : "Zadejte alespoň dva znaky."
                }}
            </template>

            <!-- TEMPLATE -->
            <template #item="{ item }">

                <div class="item" v-if="sablona == 'vzv'">
                  <div class="row">
                    <div class="col-5 text-center">
                      <img :src="'http://192.168.80.145/admin/' + item.img" class="img-fluid" />
                      <br>
                      <div class="text-danger text-bold mt-2" v-if="item.prodano == 5 || item.prodano == 1">{{ item.status_prodeje }}</div>
                    </div>
                    <div class="col-7">
                      <span class="item-name"> <b>{{ item.evidcislo }} {{ item.vyrobce }} - {{ item.pohon }}</b> </span> <br />
                      <div class="table-responsive">
                        <table class="table">
                          <tr>
                            <th>Rok výroby</th>
                            <td>{{ item.rok_vyroby }}</td>
                          </tr>
                          <tr>
                            <th>MTH</th>
                            <td v-html="item.motohodiny"></td>
                          </tr>
                          <tr>
                            <th>Typ</th>
                            <td v-html="item.typ_voziku"></td>
                          </tr>
                          <tr>
                            <th>Zdvih</th>
                            <td v-html="item.zdvih"></td>
                          </tr>
                          <tr>
                            <th>Nosnost</th>
                            <td v-html="item.nosnost"></td>
                          </tr>
                          <tr>
                            <th>Hmotnost</th>
                            <td v-html="item.hmotnost"></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="item" v-if="sablona == 'vzv-long'">
                  <div class="row">
                    <div class="col-3 text-center">
                        <img :src="$adminUrl + item.img" class="img img-fluid" />
                      <br>
                      <div class="text-danger text-bold mt-2" v-if="item.prodano == 5 || item.prodano == 1">{{ item.status_prodeje }}</div>
                    </div>
                    <div class="col-7">
                      <span class="item-name"> <b>{{ item.evidcislo }} {{ item.vyrobce }} - {{ item.pohon }}</b> </span> <br />
                      <div class="table-responsive">
                        <table class="table">
                          <tr>
                              <th>Rok výroby</th>
                              <td>{{ item.rok_vyroby }}</td>
                              <th>MTH</th>
                              <td v-html="item.motohodiny"></td>
                              <th>Typ</th>
                              <td v-html="item.typ_voziku"></td>
                          </tr>
                          <tr>
                              <th>Zdvih</th>
                              <td v-html="item.zdvih"></td>
                              <th>Nosnost</th>
                              <th v-html="item.nosnost"></th>
                              <th>Hmotnost</th>
                              <td v-html="item.hmotnost"></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="item" v-if="sablona == 'nd-long'">
                    <div class="row">
                        <div class="col-2 text-center">
                            <img :src="$adminUrl + item.cesta_primarni_foto" class="img img-fluid" />
                        </div>
                        <div class="col-10">
                          <span class="item-name"> {{ item.skladove_cislo }} <b>{{ item.nazev_polozky }}</b> </span> <br />
                          <div class="table-responsive">
                              <table class="table">
                                  <tr>
                                      <td v-html="item.kategorie_cesta"></td>
                                  </tr>
                              </table>
                          </div>
                        </div>
                    </div>
                </div>

                <div class="item" v-if="sablona == 'uzivatele'">
                    <div class="row">
                      <div class="col-12">
                          <span class="item-name"> {{ item.prijmeni }} {{ item.jmeno }}</span>
                          <br />
                      </div>
                    </div>
              </div>

              <hr>
            </template>

            <template v-if="loading" #input-end>
                <img src="../../assets/loading.gif" class="loading-indicator">
            </template>
        </cool-select>
    </div>
</template>

<script>
import { CoolSelect } from "vue-cool-select";

export default {
    components: {
        CoolSelect
    },
    props: {
        placeholder: '',
        adresa: '',
        sablona: '',
        value: '',
        hodnota: '',
        id: '',
        parametry: '',
        inputText: '',
    },
    data: () => ({
        selected: null,
        items: [],
        loading: false,
        timeoutId: null,
        noData: false,
    }),
    created() {

    },
    watch: {
        selected: function (arr) {
            if(arr != null)
            {
                let returnValue = arr;
                if (this.hodnota != '')
                {
                  returnValue = returnValue[this.hodnota];
                }
                this.$emit('ac-' + this.id, returnValue);
                //this.$root.$emit('ac-' + this.id, returnValue);
            }
        },
    },
    methods: {
        async onSearch(search) {
            const lettersLimit = 2;

            this.noData = false;
            if (search.length < lettersLimit) {
                this.items = [];
                this.loading = false;
                return;
            }
            this.loading = true;

            if(typeof this.parametry == "undefined")
            {
                this.parametry = '';
            }

            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(async () => {
                let callUrl = apiUrl + this.adresa + `?query=${search}` + this.parametry;
                const response = await fetch(
                    callUrl
                );

                let json = await response.json();
                this.items = json.data;
                this.loading = false;

                if (!this.items.length) this.noData = true;
            }, 500);
        }
    }
};
</script>

<style>
.item {
    align-items: center;
}

.item-name {
    font-size: 14px;
}

.loading-indicator {
    width: 20px;
    margin-right: 10px;
}
.table td, .table th {
    padding: 0.2rem;
}

.img {
    width : 100px;
}
</style>

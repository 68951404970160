<template>
    <label class="toggle-switch">
        <input type="checkbox" :name="toggleName" :id="toggleId" :value="checkBoxCheck" v-bind:class="{'checked': checkBoxCheck == true}">
        <span :class="'toggle-slider toggle-slider-' + colorSlider + ' round'" :id="toggleId + '_span'" @click="checkBox"></span>
    </label>
</template>

<script>
export default {
    name: "TheToggle",
    props: {
        color: {
            type: String,
            default: 'info'
        },
        setChecked: false
    },
    data() {
        return {
            colorSlider: '',
            onClick: '',
            toggleName: '',
            toggleId: '',
            checkBoxCheck: false,
        }
    },
    created() {
        this.colorSlider = this.color;
        this.toggleId = this.autoId(6);
        if (this.setChecked == true)
        {
            if (this.toggleId != '')
            {
                this.checkBoxCheck = true;
            }
        }
    },
    methods: {
        checkBox()
        {
            if (this.checkBoxCheck == false)
            {
                this.checkBoxCheck = true;
                document.getElementById(this.toggleId).checked = false;
                var element = document.getElementById(this.toggleId);
                element.classList.add("checked");
            }
            else
            {
                this.checkBoxCheck = false;
                document.getElementById(this.toggleId).checked = true;
                var element = document.getElementById(this.toggleId);
                element.classList.remove("checked");
            }
            this.$emit('checkbox', this.checkBoxCheck);
        },
        autoId(pocetZnaku)
        {
            let result = '';
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for ( let i = 0; i < pocetZnaku; i++ )
            {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
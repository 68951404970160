<template>
    <div>
        <span @click="detailPolozky(idPolozky)">{{ skladoveCislo }}</span>
        <the-modal-dialog
        :id="this.id"
        :title="this.skladoveCislo + ' - ' + this.nahradniDil.nazev_polozky"
        size="extra-large"
        buttons="storno">

      <div class="mb-5">
          <!--<the-carousel :nazev="detailPolozky.nazev_polozky" :fotoArray="detailPolozky.cesty_fotek"></the-carousel>-->
        <!--<div class="row detail-polozky">
          <template v-if="cisloPolozky > 0">
            <template v-if="detailPolozky.existuje_skladem == 1">
              <div class="col-12 text-bold text-danger mt-2 mb-2 text-center" v-if="detailPolozky.pocet_rezervaci_pol_nd_polozka > 0">
                Tato položka je rezervovaná pro prodej
              </div>
            </template>
            <template v-if="detailPolozky.existuje_skladem == 0">
              <div class="col-12 text-bold text-danger mt-2 mb-2 text-center" v-if="detailPolozky.existuje_vydej == 1">
                Tato položka již byla vyskladněna
              </div>
              <div class="col-12 text-bold text-danger mt-2 mb-2 text-center" v-if="detailPolozky.jiny_sklad == 1">
                Tato položka se nachází v jiném skladě
              </div>
            </template>

          </template>

          <div class="col-4 text-right">Skladové číslo</div>
          <div class="col-8 text-left text-bold">{{ detailPolozky.skladove_cislo }}</div>

          <div :class="'col-4 text-right'" v-if="detailPolozky.typ_polozkovani == 'pol' && cisloPolozky > 0">Číslo položky</div>
          <div :class="'col-8 text-left text-bold'" v-if="detailPolozky.typ_polozkovani == 'pol' && cisloPolozky > 0">
            <span class="text-success" v-if="detailPolozky.pouzite == 1"> {{ cisloPolozky }}</span>
            <span v-else>{{ cisloPolozky }}</span>
          </div>
          <div class="col-4 text-right">Název</div>
          <div class="col-8 text-left text-bold">{{ detailPolozky.nazev_polozky }}</div>

          <div class="col-4 text-right">Položkování</div>
          <div class="col-8 text-left text-bold">
              <span class="badge bg-secondary text-white p-1" v-if="detailPolozky.typ_polozkovani == 'pol'">Položkovatelná</span>
              <span class="badge bg-secondary text-white p-1" v-if="detailPolozky.typ_polozkovani == 'nepol_s'">Nepoložkovatelná s unikátním č.</span>
              <span class="badge bg-secondary text-white p-1" v-if="detailPolozky.typ_polozkovani == 'nepol_bez'">Nepoložkovatelná bez unikátního č.</span>
          </div>

          <div :class="'col-4 text-right'" v-if="detailPolozky.typ_polozkovani == 'pol' && cisloPolozky > 0 && detailPolozky.existuje_skladem == 1">Pozice</div>
          <div class="col-8 text-left text-bold" v-if="detailPolozky.typ_polozkovani == 'pol' && cisloPolozky > 0 && detailPolozky.existuje_skladem == 1">
            <router-link
                :to="'/detail/parkovaci-pozice/' + detailPolozky.id_pozice_regalu"
                v-if="detailPolozky.id_pozice_regalu > 0"
            >
              {{ detailPolozky.kod_regalu }} - {{ detailPolozky.kod_pozice }}
            </router-link>
            <span class="text-danger" v-if="(detailPolozky.id_pozice_regalu == 0 || detailPolozky.id_pozice_regalu == null) && detailPolozky.existuje_skladem == 1">
                        Nezaparkováno
                    </span>
          </div>
          <div :class="'col-4 text-right'" v-if="detailPolozky.typ_polozkovani != 'pol'">Počet celkem</div>
          <div class="col-8 text-left text-bold" v-if="detailPolozky.typ_polozkovani != 'pol'">
            {{ detailPolozky.pocet_mj }} {{ detailPolozky.merna_jednotka }}
          </div>
        </div>-->

        <!-- Zaparkované položky -->
        <!--<the-card class="mt-2 mb-2" :default-open="false">
          <template v-slot:header>
            Zaparkované položky
          </template>
          <template v-slot:body>
            <div class="table-content">
              <div class="table-wrapper">
                <table class="table table-bordered table-bordered-dark table-striped table-hover">
                  <thead>
                  <tr class="text-center">
                    <th>Parkovací pozice</th>
                    <th>Počet MJ</th>
                    <th>Volné místo</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(regal, id) in zaparkovanePolozky">
                    <td class="text-center" v-if="id > 0">{{ regal.zkratka_regalu }} - {{ regal.kod_pozice }}</td>
                    <td class="text-center text-danger" v-if="id == 0">nezaparkováno</td>
                    <td>{{ parametr.hodnota | formatNumber}} <span v-if="parametr.merna_jednotka != 'Žádná měrná jednotka'">{{ parametr.merna_jednotka }}</span></td>
                    <td class="text-center">
                      {{ regal.pocet_mj_nove }} /
                      <span class="text-success">{{ regal.pocet_mj_pouzite }}</span>
                    </td>
                    <td class="text-center">
                      <span class="text-danger" v-if="regal.je_mozno_zaparkovat == false">NE</span>
                      <span class="text-success" v-if="regal.je_mozno_zaparkovat == true">ANO</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </the-card>-->

        <!-- Historie parkování -->
        <!--<the-card class="mt-2 mb-2" :default-open="false">
          <template v-slot:header>
            Historie parkování
          </template>
          <template v-slot:body>
            <div class="table-content">
              <div class="table-wrapper">
                <table class="table table-bordered table-bordered-dark table-striped table-hover">
                  <thead>
                  <tr class="text-center">
                    <th>Datum</th>
                    <th>Pozice</th>
                    <th>Provedl</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(historie, id) in historieParkovani">
                    <td class="text-center"> {{ historie.datum_format }} </td>
                    <td class="text-center"> {{ historie.nazev_regalu_nova }} - {{ historie.kod_pozice_nova }} </td>
                    <td> {{ historie.prijmeni }} {{ historie.jmeno }} </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </the-card>-->

        <!-- Dodavatelé -->
        <!--<the-card class="mt-2 mb-2" :default-open="false">
          <template v-slot:header>
            Dodavatelé <small>({{ Object.keys(modelovaSkladovaCislaSpolecnosti).length }})</small>
          </template>
          <template v-slot:body>
            <div class="table-content">
              <div class="table-wrapper">
                <table class="table table-bordered table-bordered-dark table-striped table-hover">
                  <thead>
                  <tr class="text-center">
                    <th>Společnost</th>
                    <th>Skladové č.</th>
                    <th>Modelové č.</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(spolecnost, id) in modelovaSkladovaCislaSpolecnosti">
                    <td><span :title="spolecnost.nazev_spolecnosti">{{ spolecnost.nazev_spolecnosti | maxLength(20) }}</span></td>
                    <td class="">{{ spolecnost.skladove_cislo }}</td>
                    <td class="">{{ spolecnost.modelove_cislo }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </the-card>-->

        <!-- Foto -->
        <the-card class="mt-2 mb-2" :default-open="true">
            <template v-slot:header>
              Foto
            </template>
            <template v-slot:body>
              <img v-for="(fotoItem, id) in foto" :src="$adminUrl + fotoItem.foto_cesta" class="rounded float-left img img-thumbnail" alt="">
            </template>
        </the-card>

        <!-- Parametry -->
        <the-card class="mt-2 mb-2" :default-open="true">
            <template v-slot:header>
                Parametry
            </template>
            <template v-slot:body>
                <div class="table-content">
                    <div class="table-wrapper">
                        <table class="table table-bordered table-bordered-dark table-striped table-hover">
                            <thead>
                                <tr class="text-center">
                                    <th>Kategorie parametru</th>
                                    <th>Parametr</th>
                                    <th>Hodnota</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(parametr, id) in parametry">
                                    <td>{{ parametr.nazev_parametru_kategorie }}</td>
                                    <td>{{ parametr.nazev_parametru }}</td>
                                    <td>{{ parametr.hodnota}} <span v-if="parametr.merna_jednotka != 'Žádná měrná jednotka'">{{ parametr.merna_jednotka }}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </the-card>

        <!-- Skladové zásoby -->
        <!--<the-card class="mt-2 mb-5" :default-open="true">
          <template v-slot:header>
            Skladové zásoby
          </template>
          <template v-slot:body>
            <h5 class="">Celkem skladem: <b> {{ skladoveZasobyCelkem['mnozstvi_zustatek_celkem'] }} {{ detailPolozky.merna_jednotka }}</b></h5>

            <template v-for="(typSkladuArray, idTypuSkladu) in skladoveZasoby['sklady']">
              <div class="top-20">
                <div class="table-content">
                  <div class="table-wrapper">
                    <table class="table table-bordered table-striped table-hover">
                      <thead>
                      <tr class="text-center text-small">
                        <th>Spol.</th>
                        <th>Sklad</th>
                        <th>Celkem</th>
                        <th>Nové</th>
                        <th>Použité</th>
                      </tr>
                      </thead>
                      <tbody class="text-small">
                      <template v-for="(row, idSkladu) in typSkladuArray">
                        <tr v-bind:class="{'background-warning': idAktualnihoSkladu == row.id_skladu}">
                          <td>{{ row.nase_spolecnost }}</td>
                          <td>{{ row.nazev_skladu }}</td>
                          <td class="text-center">{{ row.mnozstvi_celkem | formatNumber }} {{ detailPolozky.merna_jednotka }}</td>
                          <td class="text-center">{{ row.mnozstvi_zustatek_nove | formatNumber }} {{ detailPolozky.merna_jednotka }}</td>
                          <td class="text-success text-center">{{ row.mnozstvi_zustatek_pouzite | formatNumber }} {{ detailPolozky.merna_jednotka }}</td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </template>

          </template>
        </the-card>-->

      </div>


    </the-modal-dialog>
    </div>
</template>


<script>

import TheModalDialog from "@/components/TheModalDialog";
import TheCarousel from "@/components/TheCarousel";
import TheCard from "@/components/TheCard";
import {bus} from "../main";
import axiosApiPost from "../axiosApiMethod";

export default {
  name: "DetailPolozky",
  components: {TheModalDialog, TheCarousel, TheCard},
  props : {
      idPolozky : 0,
      id: "",
      skladoveCislo: "",
  },
  data () {
      return {
          parametry : [],
          foto : [],
          nahradniDil : [],
      }
  },
  created() {

      bus.$on(this.id, args => {
          this.idPolozky = args.id_polozky;

          axiosApiPost('get-nahradni-dily-parametry', {id_polozky : this.idPolozky}, 'data').then( result => {
              this.parametry = result;
          });

          axiosApiPost('get-nahradni-dily-foto', {id_polozky : this.idPolozky}, 'data').then( result => {
            console.log(result);
            this.foto = result;
          });

          axiosApiPost('get-nahradni-dily', {id_polozky : this.idPolozky}, 'data').then( result => {
              this.nahradniDil = result[0];
          });
      })
  },
  methods: {
      detailPolozky(idPolozky)
      {
          bus.$emit('detailPolozky-' + idPolozky, {id_polozky: idPolozky});
      },
  }
}
</script>

<style scoped>
  .img {
    width: 150px;
    margin-right: 3%;
  }
</style>